<template>
<div>
    <!-- 客户重发策略 增加或修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" >
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="150px" style="margin-bottom: 12px;">
                    <el-form-item label="策略名">
                        <el-input v-model="form_data.name" placeholder="请输入策略名  " size="mini"></el-input>
                    </el-form-item>


                    <el-form-item label="验证码重发">
                        <el-radio-group v-model="form_data.repeat_onoff_yzm" size="mini">
                            <el-radio label="0">打开</el-radio>
                            <el-radio label="1">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="验证码重发次数" v-show="form_data.repeat_onoff_yzm == '0'">
                        <el-input v-model="form_data.repeat_num_yzm" placeholder="请输入重发次数" size="mini" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="验证码重发超时时间" v-show="form_data.repeat_onoff_yzm == '0'">
                        <el-input v-model="form_data.repeat_timeout_yzm " placeholder="请输入重发超时时间" style="vertical-align: middle;" oninput="value=value.replace(/[^\d]/g,'')"
                         size="mini">
                         <template slot="append">秒</template>
                         </el-input>
                    </el-form-item>

                    <el-form-item label="行业重发">
                        <el-radio-group v-model="form_data.repeat_onoff_hy" size="mini">
                            <el-radio label="0">打开</el-radio>
                            <el-radio label="1">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="行业重发次数" v-show="form_data.repeat_onoff_hy == '0'">
                        <el-input v-model="form_data.repeat_num_hy" placeholder="请输入重发次数" size="mini" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="行业重发超时时间" v-show="form_data.repeat_onoff_hy == '0'">
                        <el-input v-model="form_data.repeat_timeout_hy" placeholder="请输入重发超时时间" style="vertical-align: middle;" oninput="value=value.replace(/[^\d]/g,'')"
                         size="mini">
                         <template slot="append">秒</template>
                         </el-input>
                    </el-form-item>

                    <el-form-item label="营销重发">
                        <el-radio-group v-model="form_data.repeat_onoff_yx" size="mini">
                            <el-radio label="0">打开</el-radio>
                            <el-radio label="1">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="营销重发次数" v-show="form_data.repeat_onoff_yx == '0'">
                        <el-input v-model="form_data.repeat_num_yx" placeholder="请输入重发次数" size="mini" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="营销重发超时时间" v-show="form_data.repeat_onoff_yx == '0'">
                        <el-input v-model="form_data.repeat_timeout_yx" placeholder="请输入重发超时时间" style="vertical-align: middle;" oninput="value=value.replace(/[^\d]/g,'')"
                         size="mini">
                         <template slot="append">秒</template>
                         </el-input>
                    </el-form-item>

                    <el-form-item label="四类重发">
                        <el-radio-group v-model="form_data.repeat_onoff_sl" size="mini">
                            <el-radio label="0">打开</el-radio>
                            <el-radio label="1">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="四类重发次数" v-show="form_data.repeat_onoff_sl == '0'">
                        <el-input v-model="form_data.repeat_num_sl" placeholder="请输入重发次数" size="mini" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="四类重发超时时间" v-show="form_data.repeat_onoff_sl == '0'">
                        <el-input v-model="form_data.repeat_timeout_sl" placeholder="请输入重发超时时间" style="vertical-align: middle;" oninput="value=value.replace(/[^\d]/g,'')"
                         size="mini">
                         <template slot="append">秒</template>
                         </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
                                    
                


            </el-row>
            

           
            

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            this.dialog_visible = true;//弹出框是否可见 
            //数据清除
            this.dataClear();
            //console.log(this.prop_item);

            if(this.prop_item.type === "upd"){//修改
                this.cur_title = "客户重发策略的修改";
                setTimeout(() => {
                    //获取数据
                    this.getData();
                    
                }, 1);
            }else{
                this.cur_title = "客户重发策略的增加";
                setTimeout(() => {
                    
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"客户公司",//当前页面的标题头

            form_data:{
                id:"",//ID
                name:"",//策略名
                repeat_onoff_yzm:"0",//验证码重发  0:打开 1:关闭
                repeat_num_yzm:"1",//验证码重发次数,当验证码重发打开时生效
                repeat_timeout_yzm:"30",//验证码重发超时时间,单位秒,当验证码重发打开时生效
                repeat_onoff_hy:"0",//行业验证码重发,0:打开 1:关闭
                repeat_num_hy:"1",//行业重发次数,当行业重发打开时生效
                repeat_timeout_hy:"30",//行业重发超时时间,单位秒,当行业重发打开时生效
                repeat_onoff_yx:"0",//营销验证码重发,0:打开 1:关闭
                repeat_num_yx:"1",//营销重发次数,当营销重发打开时生效
                repeat_timeout_yx:"30",//营销重发超时时间,单位秒  当营销重发打开时生效
                repeat_onoff_sl:"0",//四类验证码重发,0:打开 1:关闭
                repeat_num_sl:"1",//四类重发次数,当四类重发打开时生效
                repeat_timeout_sl:"30",//四类重发超时时间,单位秒,当四类重发打开时生效
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //查询
        getData(){
            this.form_data.id = this.prop_item.id;//ID
            this.form_data.name = this.prop_item.name;//策略名
            this.form_data.repeat_onoff_yzm = this.prop_item.repeat_onoff_yzm+"";//验证码重发  0:打开 1:关闭
            this.form_data.repeat_num_yzm = this.prop_item.repeat_num_yzm+"";//验证码重发次数,当验证码重发打开时生效
            this.form_data.repeat_timeout_yzm = this.prop_item.repeat_timeout_yzm+"";//验证码重发超时时间,单位秒,当验证码重发打开时生效
            this.form_data.repeat_onoff_hy = this.prop_item.repeat_onoff_hy+"";//行业验证码重发,0:打开 1:关闭
            this.form_data.repeat_num_hy = this.prop_item.repeat_num_hy+"";//行业重发次数,当行业重发打开时生效
            this.form_data.repeat_timeout_hy = this.prop_item.repeat_timeout_hy+"";//行业重发超时时间,单位秒,当行业重发打开时生效
            this.form_data.repeat_onoff_yx = this.prop_item.repeat_onoff_yx+"";//营销验证码重发,0:打开 1:关闭
            this.form_data.repeat_num_yx = this.prop_item.repeat_num_yx+"";//营销重发次数,当营销重发打开时生效
            this.form_data.repeat_timeout_yx = this.prop_item.repeat_timeout_yx+"";//营销重发超时时间,单位秒  当营销重发打开时生效
            this.form_data.repeat_onoff_sl = this.prop_item.repeat_onoff_sl+"";//四类验证码重发,0:打开 1:关闭
            this.form_data.repeat_num_sl = this.prop_item.repeat_num_sl+"";//四类重发次数,当四类重发打开时生效
            this.form_data.repeat_timeout_sl = this.prop_item.repeat_timeout_sl+"";//四类重发超时时间,单位秒,当四类重发打开时生效

            
        },
        
        


        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.name)){
                this.$message.error("请填写策略名");
                return;
            }

            if(this.form_data.repeat_onoff_yzm == "0"){
                if(API.isEmtry(this.form_data.repeat_num_yzm)){
                    this.$message.error("请填写验证码重发次数");
                    return;
                }
                if(API.isEmtry(this.form_data.repeat_timeout_yzm)){
                    this.$message.error("请填写验证码重发超时时间");
                    return;
                }
            }

            if(this.form_data.repeat_onoff_hy == "0"){
                if(API.isEmtry(this.form_data.repeat_num_hy)){
                    this.$message.error("请填写行业重发次数");
                    return;
                }
                if(API.isEmtry(this.form_data.repeat_timeout_hy)){
                    this.$message.error("请填写行业重发超时时间");
                    return;
                }
            }

            if(this.form_data.repeat_onoff_yx == "0"){
                if(API.isEmtry(this.form_data.repeat_num_yx)){
                    this.$message.error("请填写营销重发次数");
                    return;
                }
                if(API.isEmtry(this.form_data.repeat_timeout_yx)){
                    this.$message.error("请填写营销重发超时时间");
                    return;
                }
            }
            if(this.form_data.repeat_onoff_sl == "0"){
                if(API.isEmtry(this.form_data.repeat_num_sl)){
                    this.$message.error("请填写四类重发次数");
                    return;
                }
                if(API.isEmtry(this.form_data.repeat_timeout_sl)){
                    this.$message.error("请填写四类重发超时时间");
                    return;
                }
            }

            var submit_obj = {};
            if(this.prop_item.type === "upd"){//修改
                submit_obj.param = "updRepeatStrategy";
                submit_obj.id = this.form_data.id;//公司ID
            }else{//增加
                submit_obj.param = "addRepeatStrategy";
            }
            submit_obj.name = this.form_data.name;//	策略名
            submit_obj.repeat_onoff_yzm = this.form_data.repeat_onoff_yzm;//	验证码重发  0:打开 1:关闭
            submit_obj.repeat_num_yzm = this.form_data.repeat_num_yzm;//	验证码重发次数,当验证码重发打开时生效
            submit_obj.repeat_timeout_yzm = this.form_data.repeat_timeout_yzm;//	验证码重发超时时间,单位秒,当验证码重发打开时生效
            submit_obj.repeat_onoff_hy = this.form_data.repeat_onoff_hy;//	行业验证码重发,0:打开 1:关闭
            submit_obj.repeat_num_hy = this.form_data.repeat_num_hy;//	行业重发次数,当行业重发打开时生效
            submit_obj.repeat_timeout_hy = this.form_data.repeat_timeout_hy;//	行业重发超时时间,单位秒,当行业重发打开时生效
            submit_obj.repeat_onoff_yx = this.form_data.repeat_onoff_yx;//	营销验证码重发,0:打开 1:关闭
            submit_obj.repeat_num_yx = this.form_data.repeat_num_yx;//	营销重发次数,当营销重发打开时生效
            submit_obj.repeat_timeout_yx = this.form_data.repeat_timeout_yx;//	营销重发超时时间,单位秒  当营销重发打开时生效
            submit_obj.repeat_onoff_sl = this.form_data.repeat_onoff_sl;//	四类验证码重发,0:打开 1:关闭
            submit_obj.repeat_num_sl = this.form_data.repeat_num_sl;//	四类重发次数,当四类重发打开时生效
            submit_obj.repeat_timeout_sl = this.form_data.repeat_timeout_sl;//	四类重发超时时间,单位秒,当四类重发打开时生效
            
            API.UserServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>