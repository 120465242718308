<template>
    <!-- 客户管理》客户重发策略管理 -->
    <div id="app_user_price_search">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">客户重发策略管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">


            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">
                
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="id" label="ID" min-width="80"  align="center" sortable> </el-table-column>
                    <el-table-column  prop="name" label="名称" min-width="160"  align="center" sortable> </el-table-column>
                    <el-table-column  label="验证码重发"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_yzm == 0 ? '打开' : '关闭'}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="验证码重发次数"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_yzm == 0 ? scope.row.repeat_num_yzm : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="验证码重发超时时间"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_yzm == 0 ? scope.row.repeat_timeout_yzm+'秒' : ''}}
                        </template>
                    </el-table-column>

                    <el-table-column  label="行业重发"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_hy == 0 ? '打开' : '关闭'}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="行业重发次数"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_hy== 0 ? scope.row.repeat_num_hy : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="行业重发超时时间"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_hy == 0 ? scope.row.repeat_timeout_hy+'秒' : ''}}
                        </template>
                    </el-table-column>

                    <el-table-column  label="营销重发"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_yx == 0 ? '打开' : '关闭'}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="营销重发次数"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_yx== 0 ? scope.row.repeat_num_yx : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="营销重发超时时间"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_yx == 0 ? scope.row.repeat_timeout_yx+'秒' : ''}}
                        </template>
                    </el-table-column>

                    <el-table-column  label="四类重发"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_sl == 0 ? '打开' : '关闭'}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="四类重发次数"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_sl== 0 ? scope.row.repeat_num_sl : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="四类重发超时时间"  min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.repeat_onoff_sl == 0 ? scope.row.repeat_timeout_sl+'秒' : ''}}
                        </template>
                    </el-table-column>

                    <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="upd(scope.row)">修改</el-button>
                            <el-button type="text" size="mini" style="color:#f56c6c"  @click="del(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>


                </el-table>
            
            </el-row>

            
            

        </el-row>

        <!-- 客户重发策略增加或修改组件 -->
        <UserRepeatStrategyAddUpd :prop_change_i="prop_repeat_strategy_add_upd_i"
            :prop_item="prop_repeat_strategy_add_upd_item"
            @result="addUpdResult"
        ></UserRepeatStrategyAddUpd>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import UserRepeatStrategyAddUpd from './user_repeat_strategy_add_upd.vue';//组件 客户公司增加或修改

//import axios from "axios";
export default {
    components: {
        UserRepeatStrategyAddUpd
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            tableData:[],//数据

            //客户重发策略增加或修改
            prop_repeat_strategy_add_upd_i:0,//客户重发策略增加或修改  组件 弹出框是否可见
            prop_repeat_strategy_add_upd_item:{type:"add"},//客户重发策略增加或修改  组件 参数对象

           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        this.tableData = this.$options.data().tableData;//单个恢复初始化
         
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 85);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);


        },

        //查询
        getData(){
            //请求接口
            API.UserServlet({
                param: "repeatStrategyList",
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },

        //修改
        upd(item){
            console.log("修改");
            this.prop_repeat_strategy_add_upd_i ++;//客户重发策略增加或修改  组件 弹出框是否可见
            this.prop_repeat_strategy_add_upd_item = item;//客户重发策略增加或修改  组件 参数对象
            this.prop_repeat_strategy_add_upd_item.type = "upd";//客户重发策略增加或修改  组件 类型  add：增加  upd:修改
        },
        //增加
        add(){
            console.log("增加");
            this.prop_repeat_strategy_add_upd_i ++;//客户重发策略增加或修改  组件 弹出框是否可见
            this.prop_repeat_strategy_add_upd_item = {type:"add"};//客户重发策略增加或修改  组件 参数对象
        },
        //客户增加或修改组件 结果回调方法
        addUpdResult(){
            //查询-- 请求接口
            this.getData();
        },
        //删除
        del(id){
            this.$confirm('此操作将永久删除该重发策略, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.UserServlet({
                        param: "delRepeatStrategy",
                        id:id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，重发策略删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },
        
        //返回
        goBack() {
            API.router_to("/user_index");
        }
    }
};

</script>

<style scoped>

</style>